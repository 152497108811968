import { FC, Fragment, useEffect, useRef, useState } from 'react'

import { MenuItem, Select } from '@mui/material'
import { captureEvent } from '@sentry/browser'
import axios from 'axios'
import { Mention, MentionsInput } from 'react-mentions'

import Loading from 'src/components/Library/Loading/Loading'
import useHubDashStore from 'src/lib/stores/hubDashStore'
import { useAuth } from 'src/Providers'

import {
  BaserowCommentPayload,
  BaserowRevisionSelectOption,
  BaserowWorkspaceUser,
  type HubDashUser,
} from '../../lib/types'

import CommentBubble, {
  convertMentionCommentToBrComment,
} from './CommentBubble'
import RevisionBubble from './RevisionBubble'

interface CommentsPanelProps {
  selectedRecord: {
    id: number
    workspaceId: number
    getWorkspaceUsers: () => Promise<BaserowWorkspaceUser[]>
    activityUpdates: any[]
  }
  table: any // Not typed
}

const getCommentUser = (
  storeList: HubDashUser[],
  name: string,
): { name: string; avatarUrl: string } => {
  const matchingStoreUser = storeList?.find((user) => user.name === name)

  return {
    name: name ?? 'Unknown User',
    avatarUrl: matchingStoreUser?.avatarUrl ?? '',
  }
}

const CommentsPanel: FC<CommentsPanelProps> = ({ selectedRecord, table }) => {
  const stafflinkWorkspaces = [183, 187, 356]

  const untaggableUsers = [1, 2, 3, 4, 67, 134]

  const [token, userList] = useHubDashStore((state) => [
    state.token,
    state.userList,
  ])

  const [usersList, setUsersList] = useState<BaserowWorkspaceUser[]>([])
  const [workspaceUsers, setWorkspaceUsers] = useState<BaserowWorkspaceUser[]>(
    [],
  )

  const [itemsFilter, setItemsFilter] = useState('activity')
  const { currentUser } = useAuth()

  useEffect(() => {
    const getBaserowWorkspaceUsers = async () => {
      const users = await selectedRecord.getWorkspaceUsers()

      const filteredUsers = stafflinkWorkspaces.includes(
        selectedRecord?.workspaceId,
      )
        ? users
        : users.filter((user) => !untaggableUsers.includes(user.user_id))

      setUsersList(filteredUsers.sort((a, b) => a.name.localeCompare(b.name)))
      setWorkspaceUsers(users)
    }

    getBaserowWorkspaceUsers()
  }, [selectedRecord.id, selectedRecord.workspaceId])

  const brApi = axios.create({
    baseURL: `${process.env.BASEROW_URL}/api`,
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  })

  const [items, setItems] = useState([])
  const [commentsLoading, setCommentsLoading] = useState(true)
  const [enteredComment, setEnteredComment] = useState('')

  const recordComments = items?.filter((item) => item?.type === 'comment')
  const recordRevisions = items?.filter((item) => item?.type === 'revision')

  const commentsEndRef = useRef(null)

  const formatChange = (field, change) => {
    switch (field.type) {
      case 'single_select':
        for (const [_key, value] of Object.entries(field.select_options)) {
          // @ts-expect-error still adding all fields
          if (value.id === change) {
            return {
              // @ts-expect-error still adding all fields
              value: value.value,
              // @ts-expect-error still adding all fields
              color: value.color,
              // @ts-expect-error still adding all fields
              id: value.id,
            }
          }
        }
        break
      case 'multiple_select':
        return change.map((option) => {
          const matchingField =
            (
              Object.values(
                field?.select_options ?? {},
              ) as BaserowRevisionSelectOption[]
            ).find((selectOption) => selectOption?.id === option) ?? null

          if (matchingField) {
            return {
              value: matchingField.value,
              color: matchingField.color,
              id: matchingField.id,
            }
          } else {
            return {
              value: 'Deleted option',
              color: 'gray',
            }
          }
        })
      case 'link_row': {
        return change.map((link) => {
          return {
            value: field?.linked_rows[link]?.value,
            id: field?.linked_rows[link]?.id,
          }
        })
      }
      case 'date': {
        return new Date(change)
      }
      case 'multiple_collaborators': {
        return change?.map((change) => {
          return { id: change?.id, name: change?.name ?? 'Unknown User' }
        })
      }
      case 'boolean':
        return change
    }
  }

  const enrichRevision = (revision) => {
    revision.type = 'revision'
    revision.created_on = revision.timestamp
    revision.changes = []
    for (const [key, value] of Object.entries(revision.fields_metadata)) {
      const field = value as unknown as any // No Types :(

      // Duration history saves format at the point in time of the revision
      // Fetch the current field format so all revisions appear the same
      if (field?.type === 'duration') {
        const matchingField =
          table?.fields?.find((tableField) => tableField?.id === field?.id) ??
          null
        if (matchingField?.duration_format) {
          field.duration_format = matchingField?.duration_format
        }
      }

      // Multiple_select history saves options at time of revision
      // Fetch the current field options so new items don't appear as "deleted"
      if (field?.type === 'multiple_select') {
        const matchingField =
          table?.fields?.find((tableField) => tableField?.id === field?.id) ??
          null
        if (matchingField) {
          field.select_options = matchingField?.select_options
        }
      }

      if (field?.type === 'multiple_collaborators') {
        const matchingField =
          table?.fields?.find((tableField) => tableField?.id === field?.id) ??
          null
        if (matchingField) {
          field.name = matchingField?.name
        }
      }

      revision.changes.push({
        fieldName:
          table.fields.find((field) => `field_${field.id}` === key)?.name ||
          'Deleted Field',
        before: revision.before[key],
        after: revision.after[key],
        field,
        beforeRecord: {
          value: formatChange(value, revision.before[key]),
          getCellValue: (_) => value,
          getCellValueAsString: (_) => revision.before[key],
        },
        afterRecord: {
          value: formatChange(value, revision.after[key]),
          getCellValue: (_) => value,
          getCellValueAsString: (_) => revision.after[key],
        },
      })
    }
    return revision
  }

  useEffect(() => {
    if (selectedRecord?.activityUpdates) {
      const updatedItems = selectedRecord?.activityUpdates.filter(
        (activityItem) => {
          return !items.some((item) => item.id === activityItem.id)
        },
      )
      for (let updatedItem of updatedItems) {
        if (updatedItem.type === 'revision') {
          updatedItem = enrichRevision(updatedItem)
        }
      }
      if (updatedItems.length > 0) {
        setItems([...items, ...updatedItems])
      }
    }
  }, [JSON.stringify(selectedRecord?.activityUpdates), items])

  const fetchItems = async () => {
    const allData = []
    const commentsResponse = await brApi.get(
      `/row_comments/${table.id}/${selectedRecord.id}/`,
    )
    const commentsData = commentsResponse.data
    for (const comment of commentsData.results) {
      comment.type = 'comment'
      allData.push(comment)
    }
    const historyResponse = await brApi.get(
      `/database/rows/table/${table.id}/${selectedRecord.id}/history/`,
    )
    const historyData = historyResponse.data

    for (const revision of historyData.results) {
      const enrichedRevision = enrichRevision(revision)
      allData.push(enrichedRevision)
    }
    allData.sort((a, b) => {
      return new Date(a.created_on).getTime() - new Date(b.created_on).getTime()
    })
    setItems(allData)
    setCommentsLoading(false)
  }

  useEffect(() => {
    if (selectedRecord) {
      fetchItems()
    }
  }, [selectedRecord.id])

  const handleCommentSubmit = async () => {
    // Reset value
    setEnteredComment('')

    // Only Create new BR Comment if value has content
    if (enteredComment) {
      const contentArray = convertMentionCommentToBrComment(enteredComment)

      const commentPayload: BaserowCommentPayload = {
        message: {
          type: 'doc',
          content: [
            {
              type: 'paragraph',
              content: contentArray,
            },
          ],
        },
      }

      try {
        await brApi.post(
          `/row_comments/${table.id}/${selectedRecord.id}/`,
          commentPayload,
        )
      } catch (error) {
        captureEvent({
          message: 'HubDash: An error occurred creating a comment.',
          level: 'warning',
          extra: {
            tableId: table.id,
            recordId: selectedRecord.id,
            errorCode: error?.response?.data?.error,
          },
        })
      }
    }
  }

  useEffect(() => {
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: 'auto' })
    }
  }, [items, itemsFilter])

  if (commentsLoading) {
    return (
      <div className="flex h-full w-full flex-col bg-gray-100 p-2">
        <Loading />
      </div>
    )
  }

  return (
    <div className="flex h-full w-full flex-col">
      <div
        className="h-12 border-b border-gray-200 bg-white pl-2"
        data-intercom-target="comments-panel-select-activity-type"
      >
        <Select
          value={itemsFilter}
          size="small"
          onChange={(e) => setItemsFilter(e.target.value)}
          className="w-36"
          sx={{
            '& fieldset': {
              border: 'none',
            },
          }}
        >
          <MenuItem
            value="activity"
            data-intercom-target="comments-panel-show-all-activity"
          >
            All activity
          </MenuItem>
          <MenuItem
            value="comments"
            data-intercom-target="comments-panel-show-comments"
          >
            Comments
          </MenuItem>
          <MenuItem
            value="revisions"
            data-intercom-target="comments-panel-show-edit-history"
          >
            Edit history
          </MenuItem>
        </Select>
      </div>
      <div className="flex flex-col gap-2 overflow-auto px-4 pt-4">
        {items?.length === 0 && itemsFilter === 'activity' && (
          <p className="w-full self-center py-8 text-center text-gray-500">
            There is no activity for this record yet.
          </p>
        )}
        {recordComments?.length === 0 && itemsFilter === 'comments' && (
          <p className="w-full self-center py-8 text-center text-gray-500">
            There are no comments for this record yet.
          </p>
        )}
        {recordRevisions?.length === 0 && itemsFilter === 'revisions' && (
          <p className="w-full self-center py-8 text-center text-gray-500">
            There is no edit history for this record yet.
          </p>
        )}
        {items.map((item, index) => (
          <Fragment key={index}>
            {item.type === 'comment' &&
              (itemsFilter === 'activity' || itemsFilter === 'comments') && (
                <div
                  key={index}
                  ref={index === items.length - 1 ? commentsEndRef : null}
                  className="px-2 pb-4"
                >
                  <CommentBubble
                    commenter={getCommentUser(userList, item?.first_name)}
                    comment={item}
                    currentUserName={currentUser.userData.name}
                    usersList={usersList}
                    brApi={brApi}
                    refetch={fetchItems}
                  />
                </div>
              )}
            {item.type === 'revision' &&
              (itemsFilter === 'activity' || itemsFilter === 'revisions') && (
                <div
                  key={index}
                  ref={index === items.length - 1 ? commentsEndRef : null}
                  className="px-2 pb-4"
                >
                  <RevisionBubble
                    commenter={getCommentUser(userList, item?.user?.name)}
                    revision={item}
                    currentUserName={currentUser.userData.name}
                    usersList={usersList}
                    workspaceUsers={workspaceUsers}
                  />
                </div>
              )}
          </Fragment>
        ))}
      </div>
      <div
        className="mt-auto flex border-t border-gray-200 px-2 pb-3 pt-3"
        data-intercom-target="hubdash-comments-panel"
        data-testid="hubdash-comments-panel"
      >
        <MentionsInput
          value={enteredComment}
          onChange={(e) => setEnteredComment(e.target.value)}
          forceSuggestionsAboveCursor
          allowSpaceInQuery
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault()
              handleCommentSubmit()
            }
          }}
          placeholder="Add a comment..."
          className="min-h-8 w-full rounded border border-gray-300 bg-gray-100"
          style={{
            input: {
              paddingLeft: '5px',
              paddingTop: '5px',
            },
            suggestions: {
              list: {
                borderRadius: '5px',
                border: '1px solid #9ca3af',
                padding: '5px',
              },
              item: {
                padding: '5px',
                borderBottom: '1px solid #f3f4f6',
                '&focused': {
                  backgroundColor: '#f3f4f6',
                },
                fontSize: '12px',
              },
            },
          }}
        >
          <Mention
            trigger="@"
            data={usersList.map((user) => ({
              id: user?.user_id,
              display: user.name,
            }))}
            displayTransform={(_, display) => `@${display}`}
          />
        </MentionsInput>
      </div>
    </div>
  )
}

export default CommentsPanel
